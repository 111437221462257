import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import {useParams} from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import DlgListUsers from '../Components/Dialogs/DlgListUsers';
import DlgPharmScriptContainer from '../Components/Dialogs/DlgPharmScriptContainer';
import { toast } from 'react-toastify';

function PharmacyDetails() {

  const {user}                                       = useSelector((state) => state.auth);
  
  const [processing, setProcessing]                                   = useState(false);
  const [dislayOrEdit, setDislayOrEdit]                               = useState("showcontent"); //showediting
  const [subPharmDetails, setSubPharmDetails]                         = useState();
  const [pharmUsersDetails, setPharmUsersDetails]                     = useState();
  const [showConnectPharmacyUser, setShowConnectPharmacyUser]         = useState();
  const [showPharmacyScriptsTabLog, setShowPharmacyScriptsTabLog]     = useState();

  const [scriptList, setScriptListing]                            = useState([]);
  const [scriptListArchive, setScriptListingArchive]              = useState([]);
  const [getTheScripts, setGetTheScripts]                         = useState(false);

  const [currentScript, setCurrentScript]                         = useState({});

  const [globalFilter, setGlobalFilter]                              = useState('');
  const [selectedScriptsUser, setSelectedScriptsUser]                = useState(null);
  const params                                                    = useParams();

  const nameRef                                = useRef();
  const streetRef                              = useRef();  
  const townRef                                = useRef();
  const cityRef                                = useRef();  
  const zipCodeRef                             = useRef();
  const phoneRef                               = useRef();

  useEffect(() => {
    collectPharmacyDetails();    
  },[])
  useEffect(() => {
    ///only search and the pharm details have something
    if(subPharmDetails){
      collectPharmScripts();   
    }
     
  },[getTheScripts])

    
  function handleTableFilter(event) {
      const newData = scriptListArchive.filter(row => {

            //return row.title.toLowerCase().includes(event.target.value.toLowerCase())
            const searchText = event.target.value.toLowerCase();

            return (
              row.title.toLowerCase().includes(searchText) ||
              row.versionCode.toLowerCase().includes(searchText) ||
              row.scriptcode.toLowerCase().includes(searchText) ||
              row.expiryDate.toLowerCase().includes(searchText) 
             )
        });

        setScriptListing(newData);
  }
    //////////___
      const header = (
        <div className="table-header flex justify-content-between">
          <h4 className="mx-0 my-1"></h4>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={handleTableFilter} placeholder="Search Script..." />
          </span>
        </div>
    );
    //////////___
     //////////___
     const linkExpirtyDateTemplate  = (rowData) => {
      return <span>{new Date(rowData.expiryDate).toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}</span>
  }
  //////////___
  //////////___
  const linkMenueScriptSatusTextTemplate = (rowData) => {
      return <div className={"stat stat" + rowData.status.text}>{rowData.status.text}</div>
   }
   //////////___
  //////////___
  const linViewScriptsSatusTextTemplate = (rowData) => {

   
    return <Button
              label="View"
              className="btn btn-rescure btsm1"
              onClick={() => selectCurrentScriptFunction(rowData)}
              />
 }
 //////////___

 const selectCurrentScriptFunction = (dataContent) => {
  
  setCurrentScript(dataContent);
  setShowPharmacyScriptsTabLog(true);
 }
  
 const collectPharmacyDetails = async () => {

      try {
        
          const res = await axios.get(CONSTANTS.API_URL +"pharmacy/pharm/"+ params.id, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

          //console.log(res.data);
          //console.log(res.data.pharmacies.seen);
          //if it has not been seen, then change it to being seen now
          if(res.data.pharmacies.seen === false){
            const res = await axios.put(CONSTANTS.API_URL +"pharmacy/pharm/change/seen/"+ params.id);
            console.log("Was not seen yet");
            console.log(res.data);
          }
          setSubPharmDetails(res.data.pharmacies);
          setPharmUsersDetails(res.data.users);
          setGetTheScripts(true);
        } catch (err) {
          console.log(err);          
        }      
  }

  const collectPharmScripts = async () => {
      
      try {
 
          const codePharm = {
            "pharmcode": subPharmDetails.pharmacycode
          }
       
            const res = await axios.put(CONSTANTS.API_URL +"scripts/list/manuscript", codePharm, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

         // console.log(res.data);
          setScriptListing(res.data); 
          setScriptListingArchive(res.data);

      } catch (err) {
          console.log(err);        
      }        
  }

  const updatePharmacyAuth = async (option) => {
 
    const approval = {
      "approve": option,
      "identity": params.id,
      "email": subPharmDetails.email
    }
    
   
    setProcessing(true); 
    try{

      const response = await axios.put(CONSTANTS.API_URL + "pharmacy/pharm/approval", approval, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

          
        
       setProcessing(false); 
       window.location.reload();
    }catch(err){
      console.log(err);
      setProcessing(false); 
    }
  }

  async function HandleEditingPharmacy(e) {
    e.preventDefault();  
    setProcessing(true);   

    try{
     
        const pharmContent = {
          "name" : nameRef.current.value,
          "street" : streetRef.current.value,
          "town" : townRef.current.value,
          "city" : cityRef.current.value,
          "zipcode" : zipCodeRef.current.value,
          "pharmacycode" : subPharmDetails.pharmacycode,
          "phone" : phoneRef.current.value,
        }


        const response = await axios.put(CONSTANTS.API_URL + "pharmacy/store/details/update", pharmContent, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
        
          
        if(response.status === 200){
          toast.success("Successfully updated the pharmacy details.");
        }
        setProcessing(false);
       // e.target.reset();
    } catch (err){
      console.log(err);
      setProcessing(false);
      toast.error("Something went wrong, please try again later.");   
    }       
  }


  const sendHostApprovedEmail = async () => {
  
     try{
    
      const res = await axios.put(CONSTANTS.API_URL +"settings/send/email/" + subPharmDetails.email);
     
          if(res.status === 200){
            toast.success("Email has been sent.")
          }else{
            toast.error("Email was not sent, try again later.")
          }
     }catch(err){
      console.log(err);
      toast.error("Something went wrong. Try again later")
     }

    
  }

  const sendHostApprovedSms = async () => {
  
    try{
      ///////////////////////
      //Send sms      

      if(subPharmDetails.phone.startsWith("011")){
        toast.warning("Telephone line, can't send SMS.");
      }else {
         const res = await axios.get(CONSTANTS.SMS_SEND +"stream=1052601&phone=" + subPharmDetails.phone);    
      
         if(res.status === 200){
          toast.success("SMS notification sent.");        
         }
        
      }

    /*
     const res = await axios.put(CONSTANTS.API_URL +"settings/send/email/" + subPharmDetails.email);
         if(res.status === 200){
           toast.success("Email has been sent.")
         }else{
           toast.error("Email was not sent, try again later.")
         }
         */
    }catch(err){
     console.log(err);
     toast.error("Something went wrong. Try again later")
    }   
 }

  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Pharmacy Detail</h2>
      <div className="card">
        <div className="card-body">
            <div className="section-blocks">
              <div className="scrol-horizontal flexme">
                <div className={"flex_sub60 form-display " + dislayOrEdit}>
                  <h4 className="lbl-title-inner">Pharmacy</h4>
                  <div className="form-content pdright30">
                    {
                      subPharmDetails && (  <form onSubmit={HandleEditingPharmacy}>                   
                        <div className="form-group">
                              <div className="lbldesc">Title:</div>
                              <input type="text" className="form-control wide100" ref={nameRef} defaultValue={subPharmDetails.title} required/>
                        </div>                      
                        <div className="form-group">
                              <div className="lbldesc">Street:</div>
                              <input type="text" className="form-control wide100" ref={streetRef} defaultValue={subPharmDetails.address.street} required/>
                        </div>                    
                  
                        <div className="form-group">
                              <div className="lbldesc">Town:</div>
                              <input type="text" className="form-control wide100" ref={townRef} defaultValue={subPharmDetails.address.town} required/>
                        </div>  
                        <div className="form-group">
                              <div className="lbldesc">City:</div>
                              <input type="text" className="form-control wide100" ref={cityRef} defaultValue={subPharmDetails.address.city} />
                        </div>
                        <div className="form-group">
                              <div className="lbldesc">ZipCode:</div>
                              <input type="text" className="form-control wide100" ref={zipCodeRef} defaultValue={subPharmDetails.address.zipcode} />                                
                        </div>
                        <div className="form-group">
                              <div className="lbldesc">Phone Number:</div>
                              <input type="text" className="form-control wide100" ref={phoneRef} defaultValue={subPharmDetails.phone} />
                        </div>
                      
                        {
                          (dislayOrEdit == "showediting") && (
                            <div className="form-group">
                                <button type="submit" className="btn btn-rescure" disabled={processing}>Safe Edit</button>                             
                          </div>
                          )
                        }                    
                      </form>)
                    }
                
                  </div>
                  <div className="edt-row">
                      {
                        (dislayOrEdit == "showcontent") && (
                          <Button
                            label="Edit"
                            className="btn btn-rescure btsm1 mr-2"
                            onClick={() => setDislayOrEdit("showediting")}
                          />
                        )
                      }
                      
                      {
                        (dislayOrEdit == "showediting") && (
                          <Button
                            label="Cancel"
                            className="btn btn-rescure btsm1 mr-2"
                            onClick={() => setDislayOrEdit("showcontent")}
                        />
                        )
                      }
                        
                  </div>
                </div>

                <div className="flex_sub40 users-display">
                    <h4 className="lbl-title-inner">User List</h4>
                    <DlgListUsers 
                      showConnectPharmacyUser={showConnectPharmacyUser} 
                      setShowConnectPharmacyUser={setShowConnectPharmacyUser}                 
                      person={user} 
                    />


                  <Button
                        label="Add User"
                        className="btn btn-rescure btsm1 mr-2"
                        onClick={() => setShowConnectPharmacyUser(true)}
                      />

                      <div className="">
                        {
                            pharmUsersDetails && (
                              pharmUsersDetails.map((logs, i) => {
                                return (<div className="row-item" key={i}>                                  
                                      <div className="user-person">
                                          {logs.name}
                                      </div>
                                  </div>
                                  )
                              })
                            )
                        }
                      </div>
                </div>
              </div>
            </div>
            <div className={"section-blocks"}>
               <h4 className="lbl-title-inner">Status</h4>
              
                    {
                      subPharmDetails && ( 
                        <div className="status-content ">
                            {
                              subPharmDetails.approve ? 
                              <div className="ctx flex-apart">
                                   <div className="cont-item">Your account has been enabled <button className="btn-linear-text" onClick={() => updatePharmacyAuth(false)}>Click to Disable</button>
                                   </div>
                                   <div className="cont-item">
                                    <button className="btn btn-rescure btsm1 mgright10" onClick={sendHostApprovedSms}>Send SMS</button>
                                    <button className="btn btn-rescure btsm1" onClick={sendHostApprovedEmail}>Send Host approved email</button>
                                   </div>
                              </div>
                              :
                              <div className="ctx">
                                 Your account has been disabled <button className="btn-linear-text" onClick={() => updatePharmacyAuth(true)}>Click to Enable</button> 
                              </div>
                            }
                         </div>
                      )
                    }
               
            </div>
            <div className="section-blocks">
              <h4 className="lbl-title-inner">Scripts</h4>
               {
                        scriptList && (
                            <DataTable
                                    value={scriptList}
                                    header={header}
                                    className="min-w-full"
                                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} scripts"
                                    responsiveLayout="scroll"
                                    globalFilter={(globalFilter) ? globalFilter : ''}
                                    selection={selectedScriptsUser}
                                    onSelectionChange={e => setSelectedScriptsUser(e.value)}
                                    rowClassName={(rowData) => rowData.processed ? 'se-processed' : 'unse-processed'}
                                >                      
                                    <Column field="fullname" header="Name"></Column>
                                    <Column field="scriptcode" header="Script Code"></Column>  
                                    <Column field="version" header="Version"></Column>   
                                    <Column field="versionCode" header="Version Code"></Column>   
                                    <Column field="origScriptNumber" header="ORN"></Column>      
                                    <Column header="Processing Date" body={linkExpirtyDateTemplate}></Column>    
                                    <Column header="Status" body={linkMenueScriptSatusTextTemplate}></Column>  
                                    <Column header="Show" body={linViewScriptsSatusTextTemplate}></Column>                                                          
                            </DataTable>
                        )
                    }
            </div>
        </div>

        {  currentScript && (
             <DlgPharmScriptContainer 
                  showPharmacyScriptsTabLog={showPharmacyScriptsTabLog} 
                  setShowPharmacyScriptsTabLog={setShowPharmacyScriptsTabLog}     
                  thisScript={currentScript}    
                  subPharmDetails={subPharmDetails}        
                  person={user} 
                />
                )
          }
        {
           processing && (
            <div className="position-center">
              <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
            </div>
           )
        }
      </div>
    </div>
  )
}

export default PharmacyDetails