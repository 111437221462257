import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Printer from '../Other/Printer';

function DlgPharmScriptContainer({showPharmacyScriptsTabLog, setShowPharmacyScriptsTabLog, thisScript,subPharmDetails , person}) {

    const [activeIndex, setActiveIndex]                             = useState(null);
    const [logsProcessing, setLogsProcessing]                       = useState(false);
    const [logUser, setLogUser]                                     = useState({});
    const [logArchiveScript, setLogArchiveScripts]                  = useState({});
    const [downloadLogs, setDownLoadLogs]                           = useState([]);
    const [dwnloadLogProcess, setDwnloadLogProcess]                 = useState(false);

    const [processMessageFeedback, setProcessMessageFeedback]       = useState("");
    const [relatedScriptsList, setRelatedScriptsLit]             = useState([]);
    const [keyORNProc, setKeyORNProc]                            = useState(false);
    const [keyNEWProc, setKeyNEWProc]                            = useState(false);

    useEffect(() => {
        if(showPharmacyScriptsTabLog === true){
            collectAllRelatedExtensions();           
        }
    },[showPharmacyScriptsTabLog])

    const toggleAccordion = (index) => {
        
      setActiveIndex(activeIndex === index ? null : index);
      if(index == 1){
         //You are on the download
         getDownloadedLogs();
      }
    };
  
    const viewThisEditLog = async  (rowData) => {
        setLogsProcessing(true);

        try{

            const resShowLogs = await axios.put(CONSTANTS.API_URL +"scripts/logs/collect/data" , rowData,{
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });

           // console.log(resShowLogs.data);
            setLogUser(resShowLogs.data.user);
            setLogArchiveScripts(resShowLogs.data.archive);
            setLogsProcessing(false);
        }catch(error){
            console.log(error);
            setLogsProcessing(false);
        }
    }

    function calculateAge(id) {
        // Extract the first two characters as the birth year
        const birthYear = parseInt(id.substring(0, 2));      
        // Get the current year
        const currentYear = new Date().getFullYear();      
        // Calculate the age
        const age = currentYear - (1900 + birthYear);      
        return age;
    }

    const closingPharmacyTabLog = () => {
        setShowPharmacyScriptsTabLog(false);
        setLogUser({});
        setLogArchiveScripts({});
        setActiveIndex(0);
    }

    const collectAllRelatedExtensions = async () => {
        
        try {
           
             if(parseInt(thisScript.version) > 1){

                const related = {
                    "scriptcode": thisScript.scriptcode,
                    "versioncode" : thisScript.versionCode
                }

                const res = await axios.put(CONSTANTS.API_URL +"scripts/extended/collection/" , related, {
                    headers: {
                        token: "Bearer "+ person.accessToken
                    }
                });
                
                console.log(res.data);
                setRelatedScriptsLit(res.data)
             }
         
          } catch (err) {
            console.log(err);
            console.log("ERRRRRRR Script");      
          }      
    
    }

    const functionToReturnFeedback = (createdAt) => {
        var returnString = "";
        var initialDate = new Date(createdAt).toLocaleDateString()
    
        const now = new Date();
        const diff = now - new Date(createdAt);
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);
    
        if (seconds < 60) {
          returnString = `${seconds} seconds ago`;
        } else if (minutes === 1) {
          returnString = `1 minute ago`;
        } else if (minutes < 60) {
          returnString = `${minutes} minutes ago`;
        } else if (hours === 1) {
          returnString = `1 hour ago`;
        } else if (hours < 24) {
          returnString = `${hours} hours ago`;
        } else if (days === 1) {
          returnString = `Yesterday`;
        } else if (days < 7) {
          returnString = `${days} days ago`;
        } else if (weeks === 1) {
          returnString = `1 week ago`;
        } else if (weeks < 4) {
          returnString = `${weeks} weeks ago`;
        } else if (months === 1) {
          returnString = `1 month ago`;
        } else if (months < 12) {
          returnString = `${months} months ago`;
        } else if (years === 1) {
          returnString = `1 year ago`;
        } else {
          returnString = `${years} years ago`;
        }
    
        return initialDate + " - " +returnString;
    }

    const getDownloadedLogs = async () => {
        setDownLoadLogs([]);
        try {
            setDwnloadLogProcess(true);
            const res = await axios.get(CONSTANTS.API_URL +"scripts/downloaded/user/logs/" + thisScript.scriptcode, {
                 headers: {
                     token: "Bearer "+ person.accessToken
                 }
             });
    
           // console.log(res.data);
            setDownLoadLogs(res.data);
            //DownloadLogs
            setDwnloadLogProcess(false);
          } catch (err) {
            console.log(err);
            console.log("ERRRRRRR Script");
            setDwnloadLogProcess(false);
          }      
    }

    const downloadOriginalScript = async () => {
        setKeyORNProc(true);
        try {
            const urlText = thisScript.origCopy.split("/").pop();
            const keyObject = {
                "key" : "classicals/" + urlText
            }
            console.log(keyObject);
            
            const res = await axios.put(CONSTANTS.API_URL +"scripts/view/signed/link/", keyObject,{
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });
            
            console.log("Original Object");
            console.log(res.data);
            window.open(res.data.url,'_blank');
            setKeyORNProc(false)
        }catch(err){
            console.log(err);
            setKeyORNProc(false);
            toast.error("Something went wrong, please try again later.")
        }
    }

    const downloadLatestScript = async () => {
        setKeyNEWProc(true);
        try {
            
            const keyObject = {
                "key" : thisScript.aws.key
            }
            console.log(keyObject);
            
            const res = await axios.put(CONSTANTS.API_URL +"scripts/view/signed/link/", keyObject,{
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });
            
            console.log("Original Object");
            console.log(res.data);
            window.open(res.data.url,'_blank');
            setKeyNEWProc(false);
        }catch(err){
            console.log(err);
            setKeyNEWProc(false);
            toast.error("Something went wrong, please try again later.")
        }
    }

    const processCurrentScript = async (status) => {

        try{
            setProcessMessageFeedback("");

            const scriptUpdateObject = {
                "id"  : thisScript._id,
                "processed"  : status
            }
    
            //console.log(thisScript);
            ////////////
            //console.log(subPharmDetails);
            
            
            const res = await axios.put(CONSTANTS.API_URL +"scripts/processed/update/status", scriptUpdateObject,{
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });    
            if(res.status == 200){
                setProcessMessageFeedback("Script has been processed.");
                createInvoice();
            }else {
                setProcessMessageFeedback("Unsuccessful, please try again later.");
            }
        
        }catch(err){
            setProcessMessageFeedback("Something went wrong, please try again later.");
        }
    }
    
    const createInvoice = async () =>{

        const d = new Date();
        const dataObject = {
            "pharmacyTitle": subPharmDetails.title,
            "pharmacyProvince": subPharmDetails.province,
            "pharmacyEmail": subPharmDetails.email,
            "scriptcode" :thisScript.scriptcode,
            "timestamp" :thisScript.generatedTimeStamp,
            "title" :thisScript.title,
            "expiryDate" :thisScript.expiryDate,
            "charge" : thisScript.payment.charge,
            "currentDate":d.getFullYear() + "-"+ d.getMonth() + "-" +d.getDate()
        }
        const blob = await pdf(<Printer dataScriptObject={dataObject} />).toBlob();

        
        saveAs(blob, "Invoice_" +  Math.round(d.getTime()) + "_" + thisScript.scriptcode + ".pdf");
    }

  return (
    <Dialog header="Script Processing" visible={showPharmacyScriptsTabLog} onHide={() => closingPharmacyTabLog()} style={{ width: '65vw' }}
            footer={
            <div>      
               {
                    processMessageFeedback && (
                        <div className="alert alert-success al-bill">{processMessageFeedback}</div>
                    )
                }               
                <Button className="btn btn-picky" label="Close" onClick={() => closingPharmacyTabLog()} />
            </div>
        }>
        <div className="modal-body-content">
            <div className="data-item list-scripts">

            {
                thisScript && (
                    <div className="scri--container">
                         <div className="accordion-item">
                                <button className="accordion-title" onClick={() => toggleAccordion(0)}>Script Details</button>
                                {activeIndex === 0 && (
                                <div className="accordion-content">
                                          <table className="table table-bordered table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <td className="lb-tab">Script Number</td>
                                                            <td>:</td>
                                                            <td className="lb-txt">{thisScript.scriptcode}</td> 
                                                            <td className="dudula"></td>                          
                                                            <td className="lb-tab">Original Script Number (NSO)</td>     
                                                            <td>:</td>                       
                                                            <td className="lb-txt">{thisScript.origScriptNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="lb-tab">Expirty Date</td>
                                                            <td>:</td>
                                                            <td className="lb-txt">{new Date(thisScript.expiryDate).toLocaleDateString(undefined, {
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric',
                                                                })}</td>
                                                            <td className="dudula"></td>
                                                            <td className="lb-tab">Version</td>
                                                            <td>:</td>
                                                            <td className="lb-txt">{thisScript.versionCode} / {thisScript.version}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="lb-tab">Full Name</td>
                                                            <td>:</td>
                                                            <td className="lb-txt">{thisScript.fullname}</td>
                                                            <td className="dudula"></td>
                                                            <td className="lb-tab">ID Number</td>
                                                            <td>:</td>
                                                            <td className="lb-txt">{thisScript.idNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="lb-tab">Age</td>
                                                            <td>:</td>
                                                            <td className="lb-txt">{thisScript.idNumber ? calculateAge(thisScript.idNumber) : 'N/A'}</td>    
                                                            <td className="dudula"></td>                    
                                                            <td className="lb-tab">Pharmacy Code</td>
                                                            <td>:</td>
                                                            <td className="lb-txt">{thisScript.pharmacycode}</td>
                                                        </tr>
                                                      
                                                            {
                                                                    thisScript.medical.medicalAidOption ? (
                                                                        <tr>
                                                                            <td className="lb-tab">Medical Aid Name </td>
                                                                            <td>:</td>
                                                                            <td className="lb-txt">{thisScript.medical.medicalAidName}</td>    
                                                                            <td className="dudula"></td>                    
                                                                            <td className="lb-tab">Medical Aid Number</td>
                                                                            <td>:</td>
                                                                            <td className="lb-txt">{thisScript.medical.medicalAidNumber}</td>   
                                                                        </tr>
                                                                    )
                                                                    :
                                                                    <tr>
                                                                         <td colspan="7">
                                                                            No Medical Aid
                                                                        </td>                                                                         
                                                                    </tr>
                                                            }
                                                            <tr>
                                                                <td className="lb-tab">Print Count</td>
                                                                <td>:</td>
                                                                <td className="lb-txt">
                                                                    {thisScript.status.printCount}
                                                                </td>    
                                                                <td className="dudula"></td>                    
                                                                <td className="lb-tab">Script Status</td>
                                                                <td>:</td>
                                                                <td className="lb-txt">{thisScript.status.textMessage}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="lb-tab">Prescriptions</td>
                                                                <td>:</td>
                                                                <td className="presccrit">
                                                                    <ul>
                                                                     {
                                                                        thisScript.prescriptions && (
                                                                            thisScript.prescriptions.map((pres, index) => {
                                                                                return (<li className="item" key={index}>
                                                                                            {pres}
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        )
                                                                    
                                                                    }
                                                                    </ul>
                                                                </td>
                                                                <td className="dudula"></td>                    
                                                                <td className="lb-tab"></td>
                                                                <td>:</td>
                                                                <td className="lb-txt"></td>
                                                            </tr>
                                                    </tbody>
                                                </table>
                                        <div className="line-item">
                                           To download the ORN (Original) Script click <span className="dwn-now" onClick={downloadOriginalScript}>Download</span> {keyORNProc && <span>...</span>} <br/>
                                           To the Script click <span className="dwn-now" onClick={downloadLatestScript}>Download</span> {keyNEWProc && <span>...</span>}
                                        </div>
                                                                    
                                          {
                                            (parseInt(thisScript.version) > 1) && (
                                                <div className="line-item external-scrxipts">
                                                    <h5>This script is an extension</h5>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                           <tr>
                                                                <th>Full Name</th>
                                                                <th>Script Code</th>
                                                                <th>Version Code</th>
                                                                <th>Expiry Date</th>
                                                                <th>Prints</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                relatedScriptsList.map((relatedItem, index) => {
                                                                    return (<tr key={index}>
                                                                        <td>{relatedItem.fullname}</td>
                                                                        <td>{relatedItem.scriptcode}</td>
                                                                        <td>{relatedItem.versionCode} / {relatedItem.version}</td>
                                                                        <td>{new Date(relatedItem.expiryDate).toLocaleDateString(undefined, {
                                                                            year: 'numeric',
                                                                            month: 'long',
                                                                            day: 'numeric',
                                                                            })}
                                                                        </td>
                                                                        <td>{relatedItem.status.printCount}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                             )
                                          }
                                </div>
                                )}
                            </div>

                            <div className="accordion-item">
                                <button className="accordion-title" onClick={() => toggleAccordion(1)}>Download Logs</button>
                                {activeIndex === 1 && (
                                <div className="accordion-content">
                                       
                                        <div className="download-cont">
                                            {
                                                dwnloadLogProcess && (
                                                    <div className="img-small-load">
                                                            <img src={CONSTANTS.BLUE_PROCESSING} className="processSp" />
                                                        </div>
                                                )
                                            }
                                            {
                                                downloadLogs && (
                                                <table className="table table-striped">
                                                    {
                                                    //downloadLogs
                                                    downloadLogs.map((downs, keyIndex) => {
                                                        return ( <tr className="logflex" key={keyIndex}>
                                                            <td className="dnItem">{functionToReturnFeedback(downs.createdAt)}</td>
                                                            <td className="dnItem">{downs.name}</td>
                                                            <td className="dnItem">{downs.surname}</td>
                                                            <td className="dnItem">{downs.email}</td>
                                                            <td className="dnItem">{downs.scriptcode}</td>
                                                        </tr>)
                                                    })
                                                    }
                                                </table>
                                                )
                                            }
                                        </div>
                                </div>
                                )}
                            </div>

                            <div className="accordion-item">
                                <button className="accordion-title" onClick={() => toggleAccordion(2)}>Edit Logs</button>
                                {activeIndex === 2 && (
                                <div className="accordion-content">
                                    <div className="row rxw">
                                        <div className="col-md-6">
                                            <div className="tab-form-logs">
                                                {
                                                    (thisScript.edits.length > 0)  && (
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                   <th>
                                                                    Edit Reason
                                                                    </th> 
                                                                    <th>
                                                                    Date
                                                                    </th> 
                                                                    <th>
                                                                    More
                                                                    </th> 
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    thisScript.edits.map((editItem, index) => {
                                                                        return (<tr key={index}>
                                                                            <td>{editItem.editLogs}</td>
                                                                            <td>{new Date(editItem.timestamp).toLocaleDateString(undefined, {
                                                                                year: 'numeric',
                                                                                month: 'long',
                                                                                day: 'numeric',
                                                                                })}
                                                                            </td>
                                                                            <td>
                                                                                <Button
                                                                                    label="View"
                                                                                    className="btn btn-rescure btsm1 mr-2"
                                                                                    onClick={() => viewThisEditLog(editItem)}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="ta-form">
                                                {
                                                    logsProcessing && (
                                                        <div className="img-small-load">
                                                            <img src={CONSTANTS.BLUE_PROCESSING} className="processSp" />
                                                        </div>
                                                    )
                                                }
                                                  <div className="box-information-archive">
                                                {
                                                    (Object.keys(logUser).length > 0) && (
                                                        <div className="archive-item">
                                                           <h4 class="lbl-title-inner">Edit Maker</h4>
                                                           <div className="archiv-content">
                                                                    <table className="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Name</td>
                                                                                <td>{logUser.name} {logUser.surname}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Email</td>
                                                                                <td>{logUser.email} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Profession</td>
                                                                                <td>{logUser.profession} </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                 </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                     (Object.keys(logArchiveScript).length > 0) && (
                                                        <div className="archive-item">
                                                           <h4 class="lbl-title-inner">Previous Script</h4>
                                                           <div className="archiv-content">
                                                                 <table className="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Script Code</td>
                                                                                <td>{logArchiveScript.scriptcode} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Full Name</td>
                                                                                <td>{logArchiveScript.fullname} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>ID Number</td>
                                                                                <td>{logArchiveScript.idNumber} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Status Message</td>
                                                                                <td>{logArchiveScript.status.textMessage} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Version</td>
                                                                                <td>{logArchiveScript.versionCode} / {logArchiveScript.version} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Expiry Date</td>
                                                                                <td>{new Date(logArchiveScript.expiryDate).toLocaleDateString(undefined, {
                                                                                year: 'numeric',
                                                                                month: 'long',
                                                                                day: 'numeric',
                                                                                })} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Prescription Items</td>
                                                                                <td className="pres-items">
                                                                                        <ul className="">
                                                                                        
                                                                                        {
                                                                                            (logArchiveScript.prescriptions.length > 0) && (
                                                                                                logArchiveScript.prescriptions.map((prescripts, index) => {
                                                                                                    return (<li key={index}>{prescripts}</li>)
                                                                                                })
                                                                                            )
                                                                                           
                                                                                        } 
                                                                                        </ul>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                 </div>
                                                        </div>
                                                     )
                                                }
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>

                        <div className="pay-display">
                        {
                            thisScript && thisScript.status?.text && (
                                <>
                                    {
                                        thisScript.status.text  === "Downloaded" && (
                                            <>
                                            {
                                                thisScript.processed ?
                                                   
                                                    thisScript.payment.payType === "Claim" ? 
                                                    <div className="">View Medical Aid</div>
                                                    :
                                                    <div className="">
                                                         <Button className="btn btn-picky btn-warning" label="Download Invoice" onClick={() => createInvoice()} />
                                                    </div>
                                                :
                                                <>
                                                {
                                                    thisScript.payment.payType === "Claim" ? (
                                                        <div className="info-invoicing">
                                                           <p> Claim from Medical Aid</p>
                                                            <Button className="btn btn-picky btn-danger" label="Medical Aid Claim" onClick={() => console.log("medical aid claim")} />
                                                        </div>
                                                        )
                                                    :
                                                    <div className="info-invoicing">
                                                       <p> Complete & Print Invoice</p>
                                                       <Button className="btn btn-picky btn-danger" label="Complete & Print Invoice" onClick={() => processCurrentScript(true)} />
                                                    </div>
                                                }                                          
                                                
                                                </>
                                            }
                                            </>
                                        )
                                    }
                                </>
                            )
                        }

                        </div>
                    </div>
                )
            }
            </div>
        </div>
    </Dialog>
  )
}

export default DlgPharmScriptContainer