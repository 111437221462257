import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Printer from './../Components/Other/Printer';

function ScriptDetails() {
    const {user}                                                        = useSelector((state) => state.auth);
    const [thisScript, setCurrentScript]                                = useState({});
    const [downloadLogs, setDownLoadLogs]                               = useState([]);
    const [relatedScriptsList, setRelatedScriptsLit]                    = useState([]);
    const [processMessageFeedback, setProcessMessageFeedback]           = useState("");

    const [subPharmDetails, setSubPharmDetails]                         = useState();
    const [prevOriginalScriptSRC, setPrevOriginalScriptSRC]             = useState("");

    const params                                                        = useParams();

    const [keyORNProc, setKeyORNProc]                            = useState(false);
    const [keyNEWProc, setKeyNEWProc]                            = useState(false);

    useEffect(() => {
        collectScriptDetails();    
      },[thisScript]);

        
 const collectScriptDetails = async () => {
      
        try {
        
            const res = await axios.get(CONSTANTS.API_URL +"scripts/details/"+ params.id, {
                 headers: {
                     token: "Bearer "+ user.accessToken
                 }
             });
   

            setCurrentScript(res.data);
            collectCurrentPharmInfo(res.data.pharmacycode)
            //DownloadLogs
            if(thisScript){
              getDownloadedLogs();
              collectAllRelatedExtensions();
            }
  
          } catch (err) {
            console.log(err);
            console.log("ERRRRRRR Script");
          }      
    }

    const getDownloadedLogs = async () => {

        try {
        
            const res = await axios.get(CONSTANTS.API_URL +"scripts/downloaded/user/logs/" + thisScript.scriptcode, {
                 headers: {
                     token: "Bearer "+ user.accessToken
                 }
             });

            setDownLoadLogs(res.data);
         
          } catch (err) {
            console.log(err);
            console.log("ERRRRRRR Script");
          }      
      }


  const collectAllRelatedExtensions = async () => {
        
    try {
       
         if(parseInt(thisScript.version) > 1){

            const related = {
                "scriptcode": thisScript.scriptcode,
                "versioncode" : thisScript.versionCode
            }

            const res = await axios.put(CONSTANTS.API_URL +"scripts/extended/collection/" , related, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
            
        
            setRelatedScriptsLit(res.data)
         }
     
      } catch (err) {
        console.log(err);
        console.log("ERRRRRRR Script");      
      }      
  }

  const functionToReturnFeedback = (createdAt) => {
        var returnString = "";
        var initialDate = new Date(createdAt).toLocaleDateString()

        const now = new Date();
        const diff = now - new Date(createdAt);
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

    if (seconds < 60) {
        returnString = `${seconds} seconds ago`;
        } else if (minutes === 1) {
        returnString = `1 minute ago`;
        } else if (minutes < 60) {
        returnString = `${minutes} minutes ago`;
        } else if (hours === 1) {
        returnString = `1 hour ago`;
        } else if (hours < 24) {
        returnString = `${hours} hours ago`;
        } else if (days === 1) {
        returnString = `Yesterday`;
        } else if (days < 7) {
        returnString = `${days} days ago`;
        } else if (weeks === 1) {
        returnString = `1 week ago`;
        } else if (weeks < 4) {
        returnString = `${weeks} weeks ago`;
        } else if (months === 1) {
        returnString = `1 month ago`;
        } else if (months < 12) {
        returnString = `${months} months ago`;
        } else if (years === 1) {
        returnString = `1 year ago`;
        } else {
        returnString = `${years} years ago`;
        }

    return initialDate + " - " +returnString;
  }

  const downloadOriginalScript = async () => {
    setKeyORNProc(true);
        try {

           const urlText = thisScript.origCopy.split("/").pop();
           let fileType = "";
           let targetOption = "";

            if(urlText.endsWith(".pdf") ){
              fileType = "application/pdf";
              targetOption = "Doc";

            }else if(urlText.endsWith(".docx") ){
              fileType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
              targetOption = "Doc";
            }

            const keyAssign = {
              "key": thisScript.origAws.key,
              "fileContent" : fileType
            }

          

            const resKey = await axios.put(CONSTANTS.API_URL +"scripts/view/signed/link" , keyAssign, {
                  headers: {
                      token: "Bearer "+ user.accessToken
                  }
              });
              
              if(targetOption === "Doc"){

                const a = document.createElement('a');
                   a.href = resKey.data.url;
                   a.target = '_blank';
                   a.click();
       
                }else {          
                    setPrevOriginalScriptSRC(resKey.data.url);          
             }

            setKeyORNProc(false)
        }catch(err){
            console.log(err);
            setKeyORNProc(false);
            toast.error("Something went wrong, please try again later.")
        }
    }

    const downloadLatestScript = async () => {
        setKeyNEWProc(true);
        try {
                        
            const keyAssign = {
              "key": thisScript.aws.key,
              "fileContent" : "application/pdf"
            }

            const resAWS = await axios.put(CONSTANTS.API_URL +"scripts/view/signed/link/", keyAssign,{
                  headers: {
                      token: "Bearer "+ user.accessToken
                  }
              });

              const a = document.createElement('a');
                a.href = resAWS.data.url;
                a.target = '_blank';
                a.click();
           
            setKeyNEWProc(false);
        }catch(err){
            console.log(err);
            setKeyNEWProc(false);
            toast.error("Something went wrong, please try again later.")
        }
    }

    const processCurrentScript = async (status) => {

        try{
            setProcessMessageFeedback("");

            const scriptUpdateObject = {
                "id"  : thisScript._id,
                "processed"  : status
            }
    
            const res = await axios.put(CONSTANTS.API_URL +"scripts/processed/update/status", scriptUpdateObject,{
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
  
            if(res.status == 200){
                setProcessMessageFeedback("Script has been processed.");
                createInvoice();
            }else {
                setProcessMessageFeedback("Unsuccessful, please try again later.");
            }
        }catch(err){
            setProcessMessageFeedback("Something went wrong, please try again later.");
        }
    }

    const collectCurrentPharmInfo = async (pharmcode) => {
      
      try {
   
       
          const res = await axios.get(CONSTANTS.API_URL +"pharmacy/pharmcode/"+ pharmcode, {
                  headers: {
                      token: "Bearer "+ user.accessToken
                  }
              });
  
          
          setSubPharmDetails(res.data);
         
        } catch (err) {
          console.log(err);             
        }        
  }

  const createInvoice = async () =>{

    const d = new Date();
    const dataObject = {
        "pharmacyTitle": subPharmDetails.title,
        "pharmacyProvince": subPharmDetails.province,
        "pharmacyEmail": subPharmDetails.email,
        "scriptcode" :thisScript.scriptcode,
        "timestamp" :thisScript.generatedTimeStamp,
        "title" :thisScript.title,
        "expiryDate" :thisScript.expiryDate,
        "charge" : thisScript.payment.charge,
        "currentDate":d.getFullYear() + "-"+ d.getMonth() + "-" +d.getDate()
    }
    const blob = await pdf(<Printer dataScriptObject={dataObject} />).toBlob();

    
    saveAs(blob, "Invoice_" +  Math.round(d.getTime()) + "_" + thisScript.scriptcode + ".pdf");
}
  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Script Details </h2>
      <div className="card">
        <div className="card-body">
           {
                  thisScript && (
                    <div className="single-details">
                      <table className="tbl-nostyle-full tbrspaces tbcellthird">
                        <tbody>
                          <tr>
                            <td>
                               <div className="sm-label">Full Name:</div> {thisScript.fullname}
                            </td>
                            <td>
                            <div className="sm-label">Scritpt Code:</div> {thisScript.scriptcode}
                            </td>
                            <td>
                            <div className="sm-label">Version:</div> {thisScript.versionCode} / {thisScript.version} 
                            </td>
                          </tr>
                          <tr>
                              <td>
                                <div className="sm-label">Medical Aid:</div> {thisScript.medical?.medicalAidOption == true ? "Yes" : "No"}
                              </td>
                              <td>
                                  <div className="sm-label">Medical Aid Name:</div> {thisScript.medical?.medicalAidName}
                              </td>
                              <td>
                                  <div className="sm-label">Medical Aid Number:</div> {thisScript.medical?.medicalAidNumber} 
                              </td>
                          </tr>
                          <tr>
                          <td>
                                <div className="sm-label">Status:</div> {thisScript.status?.text}
                            </td>
                            <td>
                               <div className="sm-label">Downloads Count:</div> {thisScript.status?.printCount}
                            </td>
                            <td>
                                <div className="sm-label">Script Confirmation:</div> { thisScript.status?.confirmation === false ? "Unconfirmed": "Confirmed"}
                            </td>                            
                          </tr>
                          <tr>
                            <td>
                                  <div className="sm-label">Valid Until:</div> {new Date(thisScript.expiryDate).toLocaleDateString(undefined, {
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                        })}
                            </td>
                            <td>                             
                            </td>
                            <td>
                                <div className="sm-label">Date Created:</div> {new Date(thisScript.createdAt).toLocaleDateString()}
                            </td>                            
                          </tr>
                          <tr>
                              <td colSpan={3}>
                                <div className="sm-label">Prescriptions:</div> 
                                {
                                          thisScript.prescriptions && (
                                            thisScript.prescriptions.map((thePres, i) => {
                                              return (<div className="mp-prescript-item" key={i}>     
                                                        {thePres}
                                                </div>
                                                )
                                            })
                                          )
                                      }
                              </td>
                             
                          </tr>
                        </tbody>
                      </table>
                 
                        <div className="foot-display">
                          <div className="line-item">
                              <div className="iti-title">Download Log</div>
                                <div className="dn-logs">
                                    {
                                        downloadLogs && (
                                          <div className="list-logs">
                                            {
                                              //downloadLogs
                                              downloadLogs.map((downs, keyIndex) => {
                                                return ( <div className="flexme logflex" key={keyIndex}>
                                                      <div className="dnFlexItem">{functionToReturnFeedback(downs.createdAt)}</div>
                                                      <div className="dnFlexItem">{downs.name}</div>
                                                      <div className="dnFlexItem">{downs.surname}</div>
                                                      <div className="dnFlexItem">{downs.email}</div>
                                                      <div className="dnFlexItem">{downs.scriptcode}</div>
                                                </div>)
                                              })
                                            }
                                          </div>
                                        )
                                    }
                                </div>
                            </div>
                          {
                              (parseInt(thisScript.version) > 1) && (
                                  <div className="line-item">
                                      <div className="iti-title">This script is an extension</div>
                                      <table className="table table-bordered">
                                          <thead>
                                              <tr>
                                                  <th>Full Name</th>
                                                  <th>Script Code</th>
                                                  <th>Version Code</th>
                                                  <th>Expiry Date</th>
                                                  <th>Prints</th>
                                                  <th>View</th>
                                              </tr>
                                          </thead>
                                          <tbody>

                                              {
                                                  relatedScriptsList.map((relatedItem, index) => {
                                                      return (<tr key={index}>
                                                          <td>{relatedItem.fullname}</td>
                                                          <td>{relatedItem.scriptcode}</td>
                                                          <td>{relatedItem.versionCode} / {relatedItem.version}</td>
                                                          <td>{new Date(relatedItem.expiryDate).toLocaleDateString(undefined, {
                                                              year: 'numeric',
                                                              month: 'long',
                                                              day: 'numeric',
                                                              })}
                                                          </td>
                                                          <td>{relatedItem.status.printCount}</td>
                                                          <td><Link to={"/script-details/" + relatedItem._id} className="vs-link">View</Link></td>
                                                      </tr>)
                                                  })
                                              }
                                          </tbody>
                                      </table>
                                  </div>
                                )
                            }
                       </div>
                    </div>
                  )
                }
        
            <div className="line-item">
              <p>
                To download the ORN (Original) Script click <span className="dwn-now" onClick={downloadOriginalScript}>Download</span> {keyORNProc && <span>...</span>} <br/>
              </p>
              {
                  prevOriginalScriptSRC && (
                    <img src={prevOriginalScriptSRC} alt="Original Script" className="image-area-original mgbot20" />
                  )
              }
              <p>
               To the Script click <span className="dwn-now" onClick={downloadLatestScript}>Download</span> {keyNEWProc && <span>...</span>}
               </p>
            </div>
            <div className="line-item">
               <div className="pay-display">
                  {
                    thisScript && thisScript.status?.text && (
                      <>
                         {
                  thisScript.status.text  === "Downloaded" && (
                      <>
                          {
                            thisScript.processed ?
                                      
                                      thisScript.payment.payType === "Claim" ? 
                                      <div className="">View Medical Aid</div>
                                      :
                                      <div className="">
                                            <Button className="btn btn-picky btn-warning" label="Download Invoice" onClick={() => createInvoice()} />
                                      </div>
                                  :
                                  <>
                                  {
                                      thisScript.payment.payType === "Claim" ? (
                                          <div className="info-invoicing">
                                              <p> Claim from Medical Aid</p>
                                              <Button className="btn btn-picky btn-danger" label="Medical Aid Claim" onClick={() => console.log("medical aid claim")} />
                                          </div>
                                          )
                                      :
                                      <div className="info-invoicing">
                                          <p> Complete & Print Invoice</p>
                                          <Button className="btn btn-picky btn-danger" label="Complete & Print Invoice" onClick={() => processCurrentScript(true)} />
                                      </div>
                                  }                                          
                                  
                                  </>
                              }
                              </>
                          )
                      }
                      </>
                    )
                  }
               </div>
               <div className="mgtop10">
                {
                      processMessageFeedback && (
                          <div className="alert alert-success al-bill">{processMessageFeedback}</div>
                      )
                  }
               </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ScriptDetails