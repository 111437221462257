import React from 'react'
import headerLogoImage from './../../assets/headerinvoice.png';
import footerImage from './../../assets/signature.jpg';
import mainFont from './../../fonts/OpenSansFont.ttf';
import lightFont from './../../fonts/OpenSansLight.ttf';
import boldFont from './../../fonts/OpenSansBold.ttf';

import { Document, Page, pdf, Image, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
    family: 'OpenSans',
    fonts: [
      { src: mainFont, fontStyle: 'normal', fontWeight: 'normal' },
      { src: lightFont, fontStyle: 'normal', fontWeight: 'light' },
      { src: boldFont, fontStyle: 'normal', fontWeight: 'bold' },
    ],
});

// Define the styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'OpenSans',
        fontSize: 12,
        padding: 20,
    },
    header: {
        marginBottom: 20,
    },
    txtHeader: {
        marginTop:15,
        fontFamily: 'OpenSans',
        fontSize: 10, // Adjust the font size here
        fontWeight: 'light',
    },
    subtotal: {
        alignItems: 'flex-end',
        padding:30
    },
    section: {
        margin: 20,
        padding: 10,
        flexGrow: 1,
      },  
    hr: {
        marginVertical: 12,
        height: 1,
        backgroundColor: '#000',
    },
    footer: {
        marginTop: 20,
        textAlign: 'center',
    },
    body: {
        flexGrow: 1,
    },
    image: {
        width: '100%',
    },
    text: {
        marginTop: 16,
        fontSize: 11,
        textAlign: 'justify',
    },
    textSt1: {
        fontSize: 11,
        textAlign: 'justify',
    },
    textB : {
        fontWeight: '900',
    }
});

function Printer({ dataScriptObject}) {

  return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image src={headerLogoImage} style={styles.image} />
                </View>
                <View style={styles.section}>  
                <View style={styles.txtHeader}>
                        <Text style={styles.txtDate}>Date: { new Date(dataScriptObject.currentDate).toLocaleDateString(undefined, {
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                    day: 'numeric',
                                                                })}</Text>
                    </View>
                   <View style={styles.txtHeader}>
                        <Text style={styles.txtDate}>{dataScriptObject.timestamp}</Text>
                        <Text style={styles.txtDate}>Valid Until: { new Date(dataScriptObject.expiryDate).toLocaleDateString(undefined, {
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                    day: 'numeric',
                                                                })}</Text>
                        <Text style={styles.txtDate}>Email: {dataScriptObject.pharmacyEmail}</Text>
                    </View>
                   
                    <View style={styles.body}>
                        <Text style={styles.text}>
                        Script Code: {dataScriptObject.scriptcode}                   
                        </Text>
                        <Text style={styles.textSt1}>
                            {dataScriptObject.title}
                        </Text>
                        <Text style={styles.textSt1}>
                            {dataScriptObject.pharmacyTitle}
                        </Text>
                        <Text style={styles.textSt1}>
                            {dataScriptObject.pharmacyProvince}
                        </Text>
                    </View>
                </View>
                <View style={styles.hr} />
                <View style={styles.subtotal}>
                    <Text style={styles.textB}>
                       Total: {dataScriptObject.charge}                   
                    </Text>
                </View>               
            </Page>
        </Document>
  )
}

export default Printer